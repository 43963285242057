

























































import Vue from "vue";
export default Vue.extend({
  // methods: {
  //   setLanguage: function(lang: string) {
  //     this.$i18n.locale = lang;
  //     localStorage.setItem("appLanguage", JSON.stringify(lang));
  //   }
  // },
  // mounted() {
  //   let lang = String(this.$i18n.fallbackLocale);
  //   if (localStorage.getItem("appLanguage"))
  //     lang = JSON.parse(String(localStorage.getItem("appLanguage")));
  //   this.setLanguage(lang);
  // }
});

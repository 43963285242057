import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#FFFFFF",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        // Version 1
        elcPrimary: "#3D5A80", // Bdazzled Blue
        elcSecondary: "#98C1D9", // Pale Cerulean
        elcThird: "#E0FBFC", // Light Cyan
        elcForth: "#EE6C4D", // Burnt Sienna
        elcFifth: "#293241" // Gunmetal
        // Version 2
        // elcPrimary: "#A8DADC",    // Powder Blue
        // elcSecondary: "#F1FAEE",   // Honeydew
        // elcThird: "#457B9D",      // Celadon Blue
        // elcForth: "#E63946",      // Imperial Red
        // elcFifth: "#1D3557"       // Prussian Blue
      }
    }
  }
});








































import Vue from "vue";
import MainMenu from "@/components/MainMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default Vue.extend({
  name: "App",

  components: {
    MainMenu,
    FooterMenu
  },

  data: () => ({
    //
  }),
  mounted() {
    this.$store.dispatch("loadNews");
  }
});

import Vue from "vue";
import Vuex from "vuex";
import Api from "@/services/api.ts";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    news: [],
    milestones: [],
    faq: [],
    gallery: [],
    goal: [],
    students: []
  },
  mutations: {
    SET_NEWS(state, news) {
      state.news = news;
    },
    SET_MILESTONES(state, milestones) {
      state.milestones = milestones;
    },
    SET_FAQ(state, faq) {
      state.faq = faq;
    },
    SET_GALLERY(state, gallery) {
      state.gallery = gallery;
    },
    SET_GOAL(state, goal) {
      state.goal = goal;
    },
    SET_STUDENTS(state,students){
      state.students = students;
    }
  },
  actions: {
    async loadNews({ commit }) {
      const response = await Api().get("news.json");
      const news = response.data.data; //.map(v => v.attributes);
      commit("SET_NEWS", news);
    },
    async loadMilestones({ commit }) {
      const response = await Api().get("milestones.json");
      const milestones = response.data.data; //.map(v => v.attributes);
      commit("SET_MILESTONES", milestones);
    },
    async loadFAQ({ commit }) {
      const response = await Api().get("faq.json");
      const faq = response.data.data; //.map(v => v.attributes);
      commit("SET_FAQ", faq);
    },
    async loadGallery({ commit }) {
      const response = await Api().get("gallery.json");
      const gallery = response.data.data; //.map(v => v.attributes);
      commit("SET_GALLERY", gallery);
    },
    async loadGoal({ commit }) {
      const response = await Api().get("goal.json");
      const goal = response.data.data; //.map(v => v.attributes);
      commit("SET_GOAL", goal);
    },
    async loadStudents({ commit }) {
      const response = await Api().get("students.json");
      const students = response.data.data; //.map(v => v.attributes);
      commit("SET_STUDENTS", students);
    }
  },
  modules: {}
});

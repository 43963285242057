var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home d-flex flex-column ma-1"},[_c('div',{staticClass:"hero_image mb-2"},[_c('div',{staticClass:"d-flex row ma-0 elcThird align-center"},[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'column col-12' : 'column col-6'},[_c('v-card-text',{staticClass:"transparent flat d-flex flex-column align-center justify-center"},[_c('div',[_c('p',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("home_hero_title"))+" ")]),_c('p',{staticClass:"text-h6 font-weight-regular",domProps:{"innerHTML":_vm._s(_vm.$t('home_hero_text'))}})])])],1),_c('div',{class:_vm.$vuetify.breakpoint.mobile
            ? 'column col-12 pa-0'
            : 'column col-6 pa-0'},[_c('v-img',{attrs:{"src":"/ELCprofile.jpg","aspect-ratio":"1","max-height":_vm.$vuetify.breakpoint.mobile ? '250px' : '460px'}})],1)])]),_c('div',{staticClass:"inline_menu mb-2"},[_c('div',{class:_vm.$vuetify.breakpoint.mobile
          ? 'd-flex flex-column justify-center'
          : 'd-flex flex-row justify-center'},[_c('v-card',{attrs:{"width":_vm.$vuetify.breakpoint.mobile ? '100%' : '33%',"color":"elcSecondary","to":"/about"}},[_c('v-card-text',[_c('div',{class:_vm.$vuetify.breakpoint.mobile
                ? 'd-flex justify-center text-center ma-1'
                : 'd-flex flex-column justify-center text-center ma-1'},[_c('v-icon',{staticClass:"mr-1",attrs:{"medium":""}},[_vm._v("mdi-account-multiple")]),_c('div',{staticClass:"title ma-1",staticStyle:{"font-family":"'Fredericka the Great' !important","font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.$t("about_headline"))+" ")]),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"caption mb-1 white--text"},[_vm._v(" "+_vm._s(_vm.$t("about_us_sub"))+" ")]):_vm._e()],1)])],1),_c('v-card',{attrs:{"width":_vm.$vuetify.breakpoint.mobile ? '100%' : '33%',"color":"elcSecondary","to":"/goals"}},[_c('v-card-text',[_c('div',{class:_vm.$vuetify.breakpoint.mobile
                ? 'd-flex justify-center text-center ma-1'
                : 'd-flex flex-column justify-center text-center ma-1'},[_c('v-icon',{staticClass:"mr-1",attrs:{"medium":""}},[_vm._v("mdi-bullseye")]),_c('div',{staticClass:"title ma-1",staticStyle:{"font-family":"'Fredericka the Great' !important","font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.$t("main_menu_goals"))+" ")]),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"caption mb-2 white--text"},[_vm._v(" "+_vm._s(_vm.$t("our_goals_sub"))+" ")]):_vm._e()],1)])],1),_c('v-card',{attrs:{"width":_vm.$vuetify.breakpoint.mobile ? '100%' : '33%',"color":"elcSecondary","to":"/gallery"}},[_c('v-card-text',[_c('div',{class:_vm.$vuetify.breakpoint.mobile
                ? 'd-flex justify-center text-center ma-1'
                : 'd-flex flex-column justify-center text-center ma-1'},[_c('v-icon',{staticClass:"mr-1",attrs:{"medium":""}},[_vm._v("mdi-image-multiple")]),_c('div',{staticClass:"title ma-1",staticStyle:{"font-family":"'Fredericka the Great' !important","font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.$t("main_menu_gallery"))+" ")]),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"caption mb-2 white--text"},[_vm._v(" "+_vm._s(_vm.$t("gallery_sub"))+" ")]):_vm._e()],1)])],1),_c('v-card',{attrs:{"width":_vm.$vuetify.breakpoint.mobile ? '100%' : '33%',"color":"elcSecondary","to":"/news"}},[_c('v-card-text',[_c('div',{class:_vm.$vuetify.breakpoint.mobile
                ? 'd-flex justify-center text-center ma-1'
                : 'd-flex flex-column justify-center text-center ma-1'},[_c('v-icon',{staticClass:"mr-1",attrs:{"medium":""}},[_vm._v("mdi-newspaper")]),_c('div',{staticClass:"title ma-1",staticStyle:{"font-family":"'Fredericka the Great' !important","font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.$t("main_menu_news"))+" ")]),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"caption mb-2 white--text"},[_vm._v(" "+_vm._s(_vm.$t("news_sub"))+" ")]):_vm._e()],1)])],1)],1)]),_c('div',{staticClass:"top_news d-flex flex-column ma-1 grey lighten-3"},[_c('div',{staticClass:"ma-3"},[_c('v-col',{staticStyle:{"position":"relative","left":"-2em"},attrs:{"cols":"8","sm":"3","md":"3"}},[_c('div',{staticClass:"display-1 pa-2 elcForth white--text"},[_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(_vm.$t("news_current")))])])]),_c('div',{staticClass:"my-4"},[_c('span',{staticClass:"pa-2 font-weight-bold elcForth white--text"},[_vm._v(_vm._s(_vm.topNews.date)+": "+_vm._s(_vm.topNews.title[_vm.$i18n.locale == "en" ? 0 : 1].value))])]),_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"mr-4",domProps:{"innerHTML":_vm._s(_vm.topNews.description[_vm.$i18n.locale == 'en' ? 0 : 1].value)}})])],1)],1)],1),_c('div',{staticClass:"d-flex justify-center ma-6"},[_c('v-btn',{staticClass:"elcForth white--text",attrs:{"to":"/news"}},[_vm._v(" "+_vm._s(_vm.$t("bnt_all_news"))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
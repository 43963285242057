<template>
  <div class="home d-flex flex-column ma-1">
    <div class="hero_image mb-2">
      <div class="d-flex row ma-0 elcThird align-center">
        <div
          :class="$vuetify.breakpoint.mobile ? 'column col-12' : 'column col-6'"
        >
          <v-card-text
            class="transparent flat d-flex flex-column align-center justify-center"
          >
            <div>
              <p class="text-h6 font-weight-bold">
                {{ $t("home_hero_title") }}
              </p>
              <p
                class="text-h6 font-weight-regular"
                v-html="$t('home_hero_text')"
              ></p>
            </div>
          </v-card-text>
        </div>
        <div
          :class="
            $vuetify.breakpoint.mobile
              ? 'column col-12 pa-0'
              : 'column col-6 pa-0'
          "
        >
          <v-img
            src="/ELCprofile.jpg"
            aspect-ratio="1"
            :max-height="$vuetify.breakpoint.mobile ? '250px' : '460px'"
          />
        </div>
      </div>
    </div>
    <!-- 
    <div class="hero_image mb-2">
      <v-img
        src="/ELCprofile.jpg"
        aspect-ratio="1"
        :max-height="$vuetify.breakpoint.mobile ? '250px' : '560px'"
      />
    </div> -->

    <div class="inline_menu mb-2">
      <div
        :class="
          $vuetify.breakpoint.mobile
            ? 'd-flex flex-column justify-center'
            : 'd-flex flex-row justify-center'
        "
      >
        <!-- About -->
        <v-card
          :width="$vuetify.breakpoint.mobile ? '100%' : '33%'"
          color="elcSecondary"
          to="/about"
        >
          <v-card-text>
            <div
              :class="
                $vuetify.breakpoint.mobile
                  ? 'd-flex justify-center text-center ma-1'
                  : 'd-flex flex-column justify-center text-center ma-1'
              "
            >
              <v-icon class="mr-1" medium>mdi-account-multiple</v-icon>
              <div
                class="title ma-1"
                style="
                font-family: 'Fredericka the Great' !important;
                font-style: normal;
              "
              >
                {{ $t("about_headline") }}
              </div>
              <div
                class="caption mb-1 white--text"
                v-if="!$vuetify.breakpoint.mobile"
              >
                {{ $t("about_us_sub") }}
              </div>
            </div>
          </v-card-text>
        </v-card>
        <!-- Goals -->
        <v-card
          :width="$vuetify.breakpoint.mobile ? '100%' : '33%'"
          color="elcSecondary"
          to="/goals"
        >
          <v-card-text>
            <div
              :class="
                $vuetify.breakpoint.mobile
                  ? 'd-flex justify-center text-center ma-1'
                  : 'd-flex flex-column justify-center text-center ma-1'
              "
            >
              <v-icon class="mr-1" medium>mdi-bullseye</v-icon>
              <div
                class="title ma-1"
                style="
                font-family: 'Fredericka the Great' !important;
                font-style: normal;
              "
              >
                {{ $t("main_menu_goals") }}
              </div>
              <div
                class="caption mb-2 white--text"
                v-if="!$vuetify.breakpoint.mobile"
              >
                {{ $t("our_goals_sub") }}
              </div>
            </div>
          </v-card-text>
        </v-card>
        <!-- Gallery -->
        <v-card
          :width="$vuetify.breakpoint.mobile ? '100%' : '33%'"
          color="elcSecondary"
          to="/gallery"
        >
          <v-card-text>
            <div
              :class="
                $vuetify.breakpoint.mobile
                  ? 'd-flex justify-center text-center ma-1'
                  : 'd-flex flex-column justify-center text-center ma-1'
              "
            >
              <v-icon class="mr-1" medium>mdi-image-multiple</v-icon>
              <div
                class="title ma-1"
                style="
                font-family: 'Fredericka the Great' !important;
                font-style: normal;
              "
              >
                {{ $t("main_menu_gallery") }}
              </div>
              <div
                class="caption mb-2 white--text"
                v-if="!$vuetify.breakpoint.mobile"
              >
                {{ $t("gallery_sub") }}
              </div>
            </div>
          </v-card-text>
        </v-card>
        <!-- News -->
        <v-card
          :width="$vuetify.breakpoint.mobile ? '100%' : '33%'"
          color="elcSecondary"
          to="/news"
        >
          <v-card-text>
            <div
              :class="
                $vuetify.breakpoint.mobile
                  ? 'd-flex justify-center text-center ma-1'
                  : 'd-flex flex-column justify-center text-center ma-1'
              "
            >
              <v-icon class="mr-1" medium>mdi-newspaper</v-icon>
              <div
                class="title ma-1"
                style="
                font-family: 'Fredericka the Great' !important;
                font-style: normal;
              "
              >
                {{ $t("main_menu_news") }}
              </div>
              <div
                class="caption mb-2 white--text"
                v-if="!$vuetify.breakpoint.mobile"
              >
                {{ $t("news_sub") }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div class="top_news d-flex flex-column ma-1 grey lighten-3">
      <!-- top news description-->
      <div class="ma-3">
        <v-col cols="8" sm="3" md="3" style="position: relative; left: -2em;">
          <div class="display-1 pa-2 elcForth white--text">
            <span class="pl-4">{{ $t("news_current") }}</span>
          </div>
        </v-col>
        <!-- Date -->
        <div class="my-4">
          <span class="pa-2 font-weight-bold elcForth white--text"
            >{{ topNews.date }}:
            {{ topNews.title[$i18n.locale == "en" ? 0 : 1].value }}</span
          >
        </div>

        <div>
          <v-row no-gutters>
            <!-- Text -->
            <v-col>
              <p
                class="mr-4"
                v-html="topNews.description[$i18n.locale == 'en' ? 0 : 1].value"
              ></p>
            </v-col>
            <!-- Image -->
            <!-- <v-col cols="6" md="4" v-if="topNews.images.length != 0">
              <v-img :src="topNews.images[0].path"> </v-img>
            </v-col> -->
          </v-row>
        </div>
      </div>

      <!-- Read more button -->
      <div class="d-flex justify-center ma-6">
        <v-btn class="elcForth white--text" to="/news">
          {{ $t("bnt_all_news") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {},
  methods: {},
  data: () => ({}),
  computed: {
    ...mapState(["news"]),
    topNews: function() {
      return this.news.slice().reverse()[0];
    }
  }
};
</script>
<style>
p a {
  color: #ee6c4d !important;
}
</style>
